import { ModuleWithProviders, NgModule } from '@angular/core';
import { SlugComponent } from './slug.component';
import { SlugService } from './slug.service';
import { SlugFieldComponent } from './slug-field/slug-field.component';
import { CommonModule } from '@angular/common';
import { TYTCommonModule } from '@tytapp/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

const DECLARATIONS = [
    SlugComponent,
    SlugFieldComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TYTCommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule
    ],
    declarations: DECLARATIONS,
    exports: DECLARATIONS
})
export class SlugsModule {
    static forRoot(): ModuleWithProviders<SlugsModule> {
        return {
            ngModule: SlugsModule,
            providers: [
                SlugService
            ]
        };
    }
}