import { inject } from '@angular/core';
import { Injectable } from '@banta/common';
import { ApiPage, ApiSlug, ApiUser, PagesApi, RouterApi, SlugsApi } from '@tytapp/api';
import { UserService } from '@tytapp/user';

export interface SlugMetadata {
    url: string;
    title: string;
}

export interface SlugType<T = any> {
    type: string;
    friendlyName: string;
    getContent(slug: ApiSlug): Promise<T>;

    /**
     * Get a URL for the given slug without performing additional requests.
     * It does not need to be canonical.
     *
     * @param slug
     */
    getUrl(slug: ApiSlug): string;

    /**
     * Get metadata for the given slug (title/URL).
     * The URL returned must be canonical.
     *
     * @param content
     */
    getMetadataForContent(content: T): Promise<SlugMetadata>;
}

@Injectable()
export class SlugService {
    private slugApi = inject(SlugsApi);
    private pagesApi = inject(PagesApi);
    private routerApi = inject(RouterApi);
    private userService = inject(UserService);

    constructor() {
        this.registerType({
            type: 'Navigation',
            friendlyName: 'Internal Navigation',
            getContent: () => undefined,
            getMetadataForContent: () => undefined,
            getUrl: () => undefined
        });

        this.registerType<ApiPage>({
            type: 'CMS::Page',
            friendlyName: 'Page',
            getContent: slug => this.pagesApi.getPage(slug.uuid).toPromise(),
            getMetadataForContent: async page => ({
                title: page.title,
                url: page.url
            }),
            getUrl: slug => `/pages/${slug.uuid}`
        });

        // NOTE(cycle-import): This cannot live in the UserModule, since SlugsModule depends on UserModule
        this.registerType<ApiUser>({
            type: 'User',
            friendlyName: 'User',
            getUrl: slug => `/community/profiles/${slug.slug}`, // this needs to be the internal URL, not the facade URL (/@username)
            getContent: async slug => await this.userService.getPublicProfile(slug.slug),
            getMetadataForContent: async content => ({
                title: content.username ? `@${content.username}` : `User #${content.id}`,
                url: content.username ? `/@${content.username}` : undefined
            })
        });
    }

    private providers = new Map<string, SlugType>();

    static registerType<T>(slugProvider: SlugType<T>) {
        inject(SlugService).registerType(slugProvider);
    }

    registerType<T>(slugProvider: SlugType<T>) {
        this.providers.set(slugProvider.type, slugProvider);
    }

    async resolveRoute(url: string) {
        try {
            return await this.routerApi.getRoute(url).toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            if (e.error === 'not_found')
                return undefined;

            throw e;
        }

    }

    async resolve(slug: string, scope = 'global') {
        try {
            return await this.slugApi.resolve(slug, scope).toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            if (e.error === 'not_found')
                return undefined;

            throw e;
        }
    }

    getProvider(type: string): SlugType {
        let provider = this.providers.get(type);
        if (!provider)
            throw new Error(`Unknown slug type ${type}`);
        return provider;
    }

    async getContent(slug: ApiSlug) {
        return await this.getProvider(slug.type).getContent(slug);
    }

    getFriendlyNameForType(type: string) {
        return this.providers.get(type).friendlyName;
    }

    async getMetadata(resolvedSlug: ApiSlug, content?: any) {
        content ??= await this.getContent(resolvedSlug);
        if (!content)
            return undefined;

        return this.providers.get(resolvedSlug.type).getMetadataForContent(content);
    }

    getContentUrl(slug: ApiSlug) {
        return this.getProvider(slug.type).getUrl(slug);
    }
}