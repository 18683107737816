/**
 * This file contains legacy static slug redirects that TYT.com always redirects (in lieu of more appropriate slugs).
 */
export const STATIC_REDIRECTS: Record<string,string> = {
    'payment-confirmation': '/', // from Offers v1
    articles:               '/reports',
    stories:                '/reports',
    podcast:                '/podcasts',
    watch:                  '/how-to-watch',
    impact:                 '/impacts',

    // BRANDS

    investigates:           '/stories/franchise/4vZLCHuQrYE4uKagy0oyMA',
    '5burningquestions':    '/stories/franchise/6kOeWkXabmEmeqWOuuwyUA',
    '5bq':                  '/stories/franchise/6kOeWkXabmEmeqWOuuwyUA',
    ringoffire:             '/stories/franchise/18k4AzKgwoMS4CgqKi62Io',
    morenews:               '/stories/franchise/18k4AzKgwoMS4CgqKi62Io',
    reportingin:            '/shows/4IhovMPNRKsoQUyys0oi6q',
    ri:                     '/shows/4IhovMPNRKsoQUyys0oi6q',
    tyt:                    '/shows/6e6Xst82zYACGGyweSOkMg',
    theyoungturks:          '/shows/6e6Xst82zYACGGyweSOkMg',
    pg:                     '/shows/1GluS8los444qmMUW8koOq',
    postgame:               '/shows/1GluS8los444qmMUW8koOq',
    specialevents:          '/shows/60DdTKm1qgCsQ0Q6GuCes6',
    nofilter:               '/shows/5eM6jGI2pGUiquc88C2iCC',
    nf:                     '/shows/5eM6jGI2pGUiquc88C2iCC',
    ap:                     '/shows/6Rhy44YyOcocg4iqmCMYMI',
    aggressiveprogressives: '/shows/6Rhy44YyOcocg4iqmCMYMI',
    breakdown:              '/shows/1b5wP1nYVgcImU2EMoeMaM',
    thebreakdown:           '/shows/1b5wP1nYVgcImU2EMoeMaM',
    damagereport:           '/shows/5Rz3k3CD7yyU488YokUoWi',
    thedamagereport:        '/shows/5Rz3k3CD7yyU488YokUoWi',
    happyhalfhour:          '/shows/rqjzepDylaEaQwmaYYWKm',
    hhh:                    '/shows/rqjzepDylaEaQwmaYYWKm',
    hh:                     '/shows/rqjzepDylaEaQwmaYYWKm',
    murderwithfriends:      '/shows/WSlENpFzUYu8kO2s6GEkM',
    mwf:                    '/shows/WSlENpFzUYu8kO2s6GEkM',
    jimmydore:              '/shows/103gDLt2SeAeEi2UQwAg0W',
    tjds:                   '/shows/103gDLt2SeAeEi2UQwAg0W',
    jd:                     '/shows/103gDLt2SeAeEi2UQwAg0W',
    nerdalert:              '/shows/59kaghMS3eqIUgYOEmooyq',
    na:                     '/shows/59kaghMS3eqIUgYOEmooyq',
    mmm:                    '/shows/4iYvJ9zJUkuaeYOSqcaCaM',
    oldschool:              '/shows/3cIboFbvKEW0uG0EC0GMoQ',
    os:                     '/shows/3cIboFbvKEW0uG0EC0GMoQ',
    interviews:             '/shows/4Ap2dAjhqwGOcC8GUig2MA',
    tytinterviews:          '/shows/4Ap2dAjhqwGOcC8GUig2MA',
    poptrigger:             '/shows/6BbVnUNYzeks6Gim6sSQQ6',
    pt:                     '/shows/6BbVnUNYzeks6Gim6sSQQ6',
    rebelhq:                '/shows/38HQ0d2oysK6O8i8qcoicC',
    hq:                     '/shows/38HQ0d2oysK6O8i8qcoicC',
    toure:                  '/shows/5wKHrrXZSgAuU6Eycqk0yu',
    toureshow:              '/shows/5wKHrrXZSgAuU6Eycqk0yu',
    touré:                  '/shows/5wKHrrXZSgAuU6Eycqk0yu',
    sports:                 '/shows/3RXuDnkCSQssICWugq2C40',
    tytsports:              '/shows/3RXuDnkCSQssICWugq2C40',
    wethepeople:            '/shows/4Vg44nEHwciYQaq8uoAICo',
    wtp:                    '/shows/4Vg44nEHwciYQaq8uoAICo',
    wtf:                    '/shows/2Nq6VLn81igGM4OsuCGeyk',
    whattheflick:           '/shows/2Nq6VLn81igGM4OsuCGeyk',
};