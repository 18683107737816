<div class="controls">
  <mat-form-field appearance="outline" floatLabel="always"
    [class.mat-form-field-invalid]="invalidSlug && (!slugReplacement || invalidSlugReplacement)">
    <mat-label>Slug</mat-label>
    <input matInput type="text"
      [disabled]="disabled"
      pattern="[a-z0-9_\-]*"
      [(ngModel)]="slug"
      (input)="onInputSlug()"
      />
      <mat-error>Must contain only lowercase letters, numbers and dashes</mat-error>

      <mat-hint [class.mat-error]="invalidSlug && (!slugReplacement || invalidSlugReplacement)">
        @if (checkingForConflicts) {
          <app-spinner [inline]="true" class="inline line"></app-spinner>
          Checking for conflicts...
        }
        @if (!checkingForConflicts && !invalidSlug) {
          Used in the URL for this content.
        }

        @if (slugGenerator) {
          @if (isGeneratedSlug) {
            Generated for you by default.
          }
          @if (!isGeneratedSlug) {
            Customized: Use Generate to reset.
          }
        }

        @if (invalidSlug) {
          @if (!checkedSlug) {
            Slug is already in use
          }
          @if (checkedSlug) {
            Slug '{{checkedSlug}}' is already in use
          }
        }
      </mat-hint>
    </mat-form-field>
    @if (slugGenerator && !isGeneratedSlug) {
      <button mat-button (click)="setGeneratedSlug()" [matTooltip]="generatorTooltip">
        Generate
      </button>
    }
  </div>

  @if (invalidSlug) {
    <div class="conflict-editor">
      @if (conflictingSlug && conflictingSlugMetadata) {
        The slug you selected is already in use for:
        <a target="_blank" [href]="conflictingSlugMetadata.url">{{ conflictingSlugType }}: {{ conflictingSlugMetadata.title }}</a>
      }
      @if (canResolveConflict) {
        <p>If it is important that slug '{{slug}}' be used for this content instead, you can reassign the slug of the
        conflicting content below.</p>
        <mat-form-field appearance="outline" floatLabel="always" [class.mat-form-field-invalid]="invalidSlugReplacement">
          <mat-label>Slug Replacement</mat-label>
          <input matInput type="text"
            [disabled]="disabled"
            pattern="[a-z0-9_\-]*"
            [(ngModel)]="slugReplacement"
            (input)="onInputSlugReplacement()"
            />
            <mat-error>Must contain only lowercase letters, numbers and dashes</mat-error>
            <mat-hint [class.mat-error]="invalidSlugReplacement">
              {{
              invalidSlugReplacement ?
              'This slug is already in use':
              'Choose a new slug to replace the conflicting one'
              }}
            </mat-hint>
          </mat-form-field>
        }
      </div>
    }